<template>
  <div class="content pitches">
    <advanced-search-index-header
      title="Pitches"
      exportType="pitch"
      :exportScope="searchScope"
      :exportSearch="searchQuery"
    ></advanced-search-index-header>
    <section class="section is-paddingless">
      <div class="columns is-marginless ordered-mobile">
        <aside class="column is-3 has-background-light hero fl-2">
          <AsidePitches :searchQuery="searchQuery" v-on:update-query="handleQueryUpdate"/>
        </aside>
        <main class="column is-paddingless fl-1">
          <SearchFilterControlPitches
            :searchQuery="searchQuery"
            v-on:update-query="handleQueryUpdate"
          />
          <div class="container p-b-90">
            <table-actions :perPage="perPage" v-on:per-page-change="setPerPage">
              <TabsPitches :scope="searchScope" v-on:set-search-scope="setSearchScope"/>
            </table-actions>
            <table class="table is-hoverable is-fullwidth">
              <TablePitchesHead
                :sortColumn="sortColumn"
                :sortOrder="sortOrder"
                :selectAll="selectAll"
                v-on:sort-change="updateSort"
                v-on:select-all="handleSelectAll"
              />
              <tbody>
                <TablePitchesRow
                  v-for="pitch in pitches"
                  :key="pitch.id"
                  :pitch="pitch"
                  :selectAll="selectAll"
                  v-on:click-edit-button="edit"
                ></TablePitchesRow>
              </tbody>
            </table>
            <pagination
              :currentPage="currentPage"
              :totalPages="totalPages"
              v-on:current-page-change="setCurrentPage"
            ></pagination>
          </div>
        </main>
      </div>
    </section>
    <form-modal
      :type="formType"
      :message="modalMessage"
      :title="formTitle"
      :model="selectedPitch"
      :isModal="isModal"
      :canDelete="$can.includes('delete-pitches')"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'

import AsidePitches from '@/components/Pitches/AsidePitches'
import TabsPitches from '@/components/Pitches/TabsPitches'
import TablePitchesHead from '@/components/Pitches/TablePitchesHead'
import TablePitchesRow from '@/components/Pitches/TablePitchesRow'
import SearchFilterControlPitches from '@/components/Pitches/SearchFilterControlPitches'

import { ALL_FOR_FORM, PITCH_ALL, PITCH_CREATE, PITCH_DELETE, PITCH_UPDATE, USER_TAG_ALL, ACCOUNT_TAG_ALL, CONTACT_ALL_FOR_FORM, SONG_ALL, SONGWRITER_ALL_FOR_FORM, USER_ALL } from '@/store/actions'

import { PITCH_CURRENT_PAGE_SET, PITCH_PER_PAGE_SET, PITCH_SORT_SET, PITCH_SEARCH_QUERY_SET, PITCH_SEARCH_SCOPE_SET } from '@/store/mutations'

export default {
  name: 'PitchIndex',
  mixins: [uiHelper],

  data() {
    return {
      currentPageMutation: PITCH_CURRENT_PAGE_SET,
      perPageMutation: PITCH_PER_PAGE_SET,
      sortMutation: PITCH_SORT_SET,
      searchQueryMutation: PITCH_SEARCH_QUERY_SET,
      searchScopeMutation: PITCH_SEARCH_SCOPE_SET,
      formTitle: 'Edit Pitch',
      formType: null,
      formCanDelete: false,
      isModal: false,
      selectedPitch: {},
      modalMessage: null,
      selectAll: false
    }
  },
  components: {
    AsidePitches,
    TabsPitches,
    TablePitchesHead,
    TablePitchesRow,
    SearchFilterControlPitches
  },
  created: function () {
    this.$store.dispatch(USER_TAG_ALL)
    this.$store.dispatch(ACCOUNT_TAG_ALL)
    this.$store.dispatch(USER_ALL)
    this.$store.dispatch(ALL_FOR_FORM, 'artist')
    this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    this.$store.dispatch(SONG_ALL)
    this.$store.dispatch(SONGWRITER_ALL_FOR_FORM)
  },
  computed: {
    resourceState() {
      return this.$store.state.pitch
    },

    currentPage() {
      return this.$store.state.pitch.currentPage
    },

    totalPages() {
      return this.$store.state.pitch.totalPages
    },

    searchQuery() {
      return this.$store.getters.getPitchSearchQuery
    },

    pitches() {
      return this.$store.state.pitch.pitches
    },

    searchScope() {
      return this.$store.state.pitch.searchScope
    }
  },

  beforeCreate: function () {
    document.body.className = 'pitches'
  },

  methods: {
    handleQueryUpdate(query) {
      this.setSearchQuery(query, this)
    },

    handleSelectAll(event) {
      this.selectAll = event.target.checked
    },

    load() {
      this.$store.dispatch(PITCH_ALL)
    },

    edit(pitch) {
      this.clearModal()
      this.formTitle = `Edit Pitch For - ${pitch.song_title}`
      this.formCanDelete = true
      this.formType = 'pitch'
      this.isModal = !this.isModal
      this.selectedPitch = pitch
    },

    save(data) {
      let self = this
      if (this.selectedPitch.id) {
        this.$store.dispatch(PITCH_UPDATE, {
          id: this.selectedPitch.id,
          data: data
        })
          .then(function (data) {
            // there was a bug where the updated pitch data was not displaying on the pitch index.
            // the issue was that the result was returned before the pitch was reindexed.
            // setting a timeout allows the reindexing to happen and return the updated data.
            // this could be reported again but it _should_ only occur
            // if a user updates several pitches in quick succession
            setTimeout(function () {
              self.clearModal()
              self.load()
            }, 500)
          }, function (err) {
            self.clearModal()
            self.$store.commit('error', err)
          })
      } else {
        this.$store.dispatch(PITCH_CREATE, data)
          .then(function (data) {
            self.$router.push({ name: 'pitches.show', params: { id: data.id, propped: data } })
          })
      }
    },

    destroy: function (pitch) {
      this.$store.dispatch(PITCH_DELETE, pitch.id)
      this.clearModal()
      this.load()
    },

    clearModal() {
      this.isModal = false
      this.formCanDelete = false
      this.formTitle = ''
      this.selectedPitch = {}
      this.modalMessage = null
    }
  }
}
</script>
