<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeIn faster"
    leave-active-class="animated fadeOut faster"
  >
    <div id="datepicker-modal" class="modal is-active" v-if="type">
      <div class="modal-background"></div>

      <div class="modal-content card">
        <div class="card-content">
          <div class="title">{{ localData.title }}</div>
          <div class="level m-t-5">
            <div
              v-if="type === 'range'"
              class="level-item has-text-centered"
              style="flex-shrink: 1 !important"
            >
              <VueCtkDateTimePicker
                :range="true"
                :only-date="true"
                label="Date Modified"
                format="YYYY-MM-DD"
                formatted="MM/DD/YYYY"
                output-format="YYYY-MM-DD"
                color="#57c1ee"
                button-color="#57c1ee"
                :inline="true"
                v-model="localData.data"
                :auto-close="true"
                @input="getInput"
              ></VueCtkDateTimePicker>
            </div>
            <div
              v-else
              class="level-item has-text-centered"
              style="flex-shrink: 1 !important"
            >
              <VueCtkDateTimePicker
                :range="false"
                :only-date="true"
                label="Date Modified"
                format="YYYY-MM-DD"
                formatted="MM/DD/YYYY"
                output-format="YYYY-MM-DD"
                color="#57c1ee"
                button-color="#57c1ee"
                :inline="true"
                v-model="localData.data"
                :auto-close="true"
                @input="getInput"
              ></VueCtkDateTimePicker>
            </div>
          </div>
          <div class="level is-mobile">
            <div class="level-item">
              <button class="button is-primary is-inverted" @click="close">
                Cancel
              </button>
            </div>
          </div>
        </div>
        <button class="modal-close is-large" @click="close"></button>
      </div>
    </div>
  </transition>
</template>

<script>
import { DATEPICKER_CLOSE } from '@/store/actions'
import { DATEPICKER_DATA_SET } from '@/store/mutations'

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: 'DatepickerModal',

  data() {
    return {
      localData: null
    }
  },

  watch: {
    type: function (newValue, oldValue) {
      if (newValue !== null) {
        this.localData = Object.assign({}, this.data)
      } else {
        this.localData = {
          data: null,
          title: ''
        }
      }
    }
  },

  computed: {
    data() {
      return this.$store.getters.getDatepickerData
    },

    type() {
      return this.$store.getters.getDatepickerType
    }
  },

  methods: {
    getInput(data) {
      if (this.type === 'range') {
        if (data.start && data.end) {
          this.close()
        }
      } else {
        if (data !== null) {
          this.close()
        }
      }
    },
    close() {
      let data

      if (this.type === 'range' && this.localData.data) {
        data = {
          start: this.localData.data.start,
          end: this.localData.data.end,
          shortcut: this.localData.data.shortcut
        }
      } else {
        data = this.localData.data
      }

      this.$store.commit(DATEPICKER_DATA_SET, {
        data: data,
        title: this.localData.title,
        key: this.localData.key
      })
      this.$store.dispatch(DATEPICKER_CLOSE)
    }
  }
}
</script>
