<template>
  <div class="content home">
    <PageHeaderPlaylist :playlist="playlist" v-on:edit-playlist="edit"></PageHeaderPlaylist>
    <section class="section is-paddingless">
      <div class="columns is-marginless">
        <aside class="column is-2 hero">
          <AsidePlaylist :playlist="playlist"></AsidePlaylist>
        </aside>
        <main class="column is-paddingless">
          <div class="container p-b-30">
            <table class="table is-hoverable is-fullwidth">
              <TablePlaylistHead />
                <draggable group="songs" @start="drag=true" @end="dragEnd" tag="tbody">
                  <tr class="is-draggable" v-for="audioFile in audioFiles" :key="audioFile.id" :audioFile="audioFile" :audioFileId="audioFile.id">
                    <td class="table-icon-play is-narrow"></td>
                    <td>{{audioFile.song | orBlank('title')}}</td>
                    <td>{{audioFile.file_name}}</td>
                    <td>{{audioFile.song | orBlank('songwriter_names')}}</td>
                    <td>
                      <a v-if="$can.includes('update-playlists')" class="table-icon tooltip m-r-10"
                        data-tooltip="Remove Song" @click="removeAudioFile(playlist, audioFile)">
                        <IconX />
                      </a>
                    </td>
                  </tr>
                </draggable>
            </table>
          </div>
          <div class="container">
          <section class="single-info single-aside-details--tags is-hidden-desktop">
            <label class="label">Tags</label>
            <tag-control taggableType="playlist" :taggableId="playlist.id" />
          </section>
            <transition enter-active-class="animated fadeIn fast">
              <div v-if="playlist.id">
                <note-control :notableId="playlist.id" notableType="playlist"></note-control>
                <PitchGroupEmailsPlaylist :playlist="playlist"></PitchGroupEmailsPlaylist>
              </div>
            </transition>
          </div>
        </main>
      </div>
    </section>
    <form-modal type="playlist" :title="formTitle" :model="selectedPlaylist" :isModal="isModal"
      :canDelete="formCanDelete" v-on:clear-modal="clearModal" v-on:save-record="save" v-on:delete-record="destroy">
    </form-modal>
  </div>
  <!-- /home -->
</template>

<script>
import _ from 'lodash'
import PageHeaderPlaylist from '@/components/Playlist/PageHeaderPlaylist'
import AsidePlaylist from '@/components/Playlist/AsidePlaylist'
import TablePlaylistHead from '@/components/Playlist/TablePlaylistHead'
import PitchGroupEmailsPlaylist from '@/components/Playlist/PitchGroupEmailsPlaylist'
import store from '@/store'
import draggable from 'vuedraggable'

import {
  IconX
} from '@/Icons/IconBase'

import {
  ALL_FOR_FORM,
  CONTACT_ALL_FOR_FORM,
  PLAYLIST_READ,
  PLAYLIST_DELETE,
  PLAYLIST_UPDATE,
  PLAYLIST_AUDIO_FILE_DELETE,
  PLAYLIST_AUDIO_FILE_REPOSITION
} from '@/store/actions'

import {
  PLAYLIST_SET
} from '@/store/mutations'

export default {
  name: 'Playlist',
  components: {
    PageHeaderPlaylist,
    AsidePlaylist,
    TablePlaylistHead,
    PitchGroupEmailsPlaylist,
    IconX,
    draggable
  },

  props: {
    propped: Object
  },

  data() {
    return {
      formTitle: 'Edit Playlist',
      formCanDelete: false,
      isModal: false,
      selectedPlaylist: {}
    }
  },

  computed: {

    audioFiles() {
      return _.sortBy(this.playlist.audio_files, ['position'])
    },

    playlist() {
      if (this.$store.getters.getPlaylist.id) return this.$store.getters.getPlaylist
      if (this.propped) return this.propped

      return {
        title: ''
      }
    },

    isLoading() {
      return this.$store.state.playlist.status === 'loading'
    },

    storeStatus() {
      return this.$store.state.playlist.status
    }
  },

  watch: {
    storeStatus: function (newValue, oldValue) {
      if (newValue === 'deleted') {
        this.$router.push({
          name: 'playlists.index'
        })
      }
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(PLAYLIST_READ, to.params.id)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(PLAYLIST_READ, to.params.id)
    next()
  },

  beforeCreate: function () {
    document.body.className = 'single'
  },

  destroyed: function () {
    this.$store.commit(PLAYLIST_SET, {})
  },

  methods: {
    dragEnd(event) {
      let position = event.newIndex + 1
      let audioFileId = event.item.attributes.audioFileId.value

      this.$store.dispatch(PLAYLIST_AUDIO_FILE_REPOSITION, {
        playlistId: this.playlist.id,
        audioFileId: audioFileId,
        position: position
      })
    },

    edit() {
      this.clearModal()
      this.formTitle = 'Edit Playlist'
      this.formCanDelete = true
      this.isModal = !this.isModal
      this.selectedPlaylist = this.playlist
      this.$store.dispatch(ALL_FOR_FORM, 'artist')
      this.$store.dispatch(CONTACT_ALL_FOR_FORM)
    },

    save(data) {
      let updateData = {
        title: data.title,
        creation_date: data.creation_date,
        pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
        shared: data.shared ? data.shared : false,
        team: data.team ? data.team : false,
        comps: data.comps ? data.comps : false,
        artist_ids: data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (
          artist) {
          return artist.id
        }) : data.pitch_artists.id ? [data.pitch_artists.id] : []

      }

      this.$store.dispatch(PLAYLIST_UPDATE, {
        id: this.playlist.id,
        data: updateData
      })
      this.clearModal()
    },

    destroy(playlist) {
      let self = this
      this.$store.dispatch(PLAYLIST_DELETE, playlist.id)
        .then(function (resp) {
          self.$router.push({
            name: 'playlists.index'
          })
        }, function (resp) {
          self.$store.commit('error', 'Unable to delete playlist.')
        })
    },

    removeAudioFile(playlist, audioFile) {
      let self = this
      let songTitle = audioFile.song.title
      self.$store.dispatch('confirm', `Are you sure you want to remove "${songTitle}" from this playlist?`)
        .then(
          resp => {
            if (resp === true) {
              this.$store.dispatch(PLAYLIST_AUDIO_FILE_DELETE, { playlistId: playlist.id, audioFileId: audioFile.id })
                .then(function (resp) {
                  self.$store.commit('success', `"${songTitle}" removed from playlist.`)
                }, function (resp) {
                  self.$store.commit('error', `Unable to remove "${songTitle}" from playlist.`)
                })
            }
          })
        .catch(err => {
          console.log(err)
        })
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
    }
  }
}
</script>
