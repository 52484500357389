<template>
  <a
    class="clear-button tooltip is-tooltip-left"
    data-tooltip="Clear Queue"
    @click.prevent="clearQueue"
  >
    <IconTrash />
  </a>
</template>

<script>
import { IconTrash } from '@/Icons/IconBase'
import {
  QUEUED_AUDIO_FILE_USER_ALL,
  QUEUED_AUDIO_FILE_USER_DELETE
} from '@/store/actions'

export default {
  name: 'QueueClearButton',
  components: {
    IconTrash
  },
  methods: {
    clearQueue() {
      let self = this
      self.$store
        .dispatch('confirm', 'Are you sure you want to clear the queue?')
        .then((resp) => {
          if (resp === true) {
            this.$store
              .dispatch(QUEUED_AUDIO_FILE_USER_ALL)
              .then(function (data) {
                if (data) {
                  data.forEach(function (queuedAudioFile) {
                    self.$store.dispatch(
                      QUEUED_AUDIO_FILE_USER_DELETE,
                      queuedAudioFile.id
                    )
                  })
                }
              })
          }
        })
    }
  }
}
</script>
