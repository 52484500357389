import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

import {
  PLAYLIST_ALL,
  PLAYLIST_CREATE,
  PLAYLIST_READ,
  PLAYLIST_UPDATE,
  PLAYLIST_DELETE,
  PLAYLIST_DUPLICATE,
  PLAYLIST_CD_LABEL_CREATE,
  PLAYLIST_AUDIO_FILE_DELETE,
  PLAYLIST_AUDIO_FILE_REPOSITION
} from '../actions'

import {
  CLEAR_STORE,
  PLAYLIST_LOADING,
  PLAYLIST_SUCCESS,
  PLAYLIST_ERROR,
  PLAYLIST_CREATED,
  PLAYLIST_UPDATED,
  PLAYLIST_DELETED,
  PLAYLIST_SET,
  PLAYLISTS_SET,
  PLAYLIST_CURRENT_PAGE_SET,
  PLAYLIST_PER_PAGE_SET,
  PLAYLIST_TOTAL_PAGES_SET,
  PLAYLIST_SORT_SET,
  PLAYLIST_SEARCH_QUERY_SET,
  PLAYLIST_SEARCH_SCOPE_SET,
  PLAYLIST_REMOVE_QUERY_ARRAY_VALUE
} from '../mutations'

export default {
  state: {
    status: '',
    playlists: [],
    playlist: {},
    perPage: 15,
    currentPage: 1,
    totalPages: 0,
    sortColumn: null,
    sortOrder: null,
    searchScope: 'all',
    searchQuery: {
      title: null,
      pitch_to_contact: null,
      pitch_artists: null,
      notes: null,
      description: null,
      song_titles: null,
      type: null,
      creation_date: null,
      userTag: null,
      accountTag: null,
      tempoTag: null,
      vocalTag: null,
      audioTag: null,
      genreTag: null,
      emotionTag: null,
      team: null
    }
  },

  getters: {
    getPlaylists: state => state.playlists,
    isPlaylistsLoaded: state => !!state.playlists,
    getPlaylist: state => state.playlist,
    isPlaylistLoaded: state => !!state.playlist,
    getPlaylistSearchQuery: state => state.searchQuery
  },

  mutations: {
    [PLAYLIST_LOADING]: (state) => {
      state.status = 'loading'
    },
    [PLAYLIST_SUCCESS]: (state) => {
      state.status = 'success'
    },
    [PLAYLIST_CREATED]: (state, data) => {
      state.playlists.unshift(data)
    },
    [PLAYLIST_DELETED]: (state, id) => {
      let index = state.playlists.findIndex(playlist => parseInt(playlist.id) === parseInt(id))
      state.playlists.splice(index, 1)
    },
    [PLAYLIST_UPDATED]: (state, data) => {
      if (state.playlists.length) {
        let index = state.playlists.findIndex(playlist => parseInt(playlist.id) === parseInt(data.id))
        state.playlists[index].title = data.title
        state.playlists[index].creation_date = data.creation_date
        state.playlists[index].type = data.type
        state.playlists[index].pitch_to_contact = data.pitch_to_contact
        state.playlists[index].pitch_artists = data.pitch_artists
        state.playlists[index].pitch_for_artist_names = data.pitch_for_artist_names
      }

      if (state.playlist && parseInt(state.playlist.id) === parseInt(data.id)) {
        state.playlist.title = data.title
        state.playlist.creation_date = data.creation_date
        state.playlist.type = data.type
        state.playlist.pitch_to_contact = data.pitch_to_contact
        state.playlist.pitch_artists = data.pitch_artists
        state.playlist.pitch_for_artist_names = data.pitch_for_artist_names
      }
    },
    [PLAYLIST_ERROR]: (state) => {
      state.status = 'error'
    },
    [PLAYLIST_SET]: (state, resp) => {
      Vue.set(state, 'playlist', resp)
    },
    [PLAYLISTS_SET]: (state, resp) => {
      Vue.set(state, 'playlists', resp)
    },
    [PLAYLIST_PER_PAGE_SET]: (state, value) => {
      state.perPage = value
    },
    [PLAYLIST_CURRENT_PAGE_SET]: (state, value) => {
      state.currentPage = value
    },
    [PLAYLIST_TOTAL_PAGES_SET]: (state, value) => {
      state.totalPages = value
    },
    [PLAYLIST_SORT_SET]: (state, {
      column,
      order
    }) => {
      state.sortColumn = column
      state.sortOrder = order
    },
    [PLAYLIST_SEARCH_QUERY_SET]: (state, query) => {
      state.searchQuery = query
    },
    [PLAYLIST_SEARCH_SCOPE_SET]: (state, value) => {
      state.searchScope = value
    },
    [PLAYLIST_REMOVE_QUERY_ARRAY_VALUE]: (state, {
      field,
      index
    }) => {
      state.searchQuery[field].splice(index, 1)
    },
    [CLEAR_STORE]: (state) => {
      Vue.set(state, 'playlist', {})
      Vue.set(state, 'playlists', {})
    }
  },

  actions: {
    [PLAYLIST_ALL]: ({
      commit,
      state,
      getters
    }) => {
      commit(PLAYLIST_LOADING)

      let accountId = getters.getActiveAccountId

      if (!accountId) {
        commit(PLAYLIST_ERROR)
        console.log('no account!')
        return false
      }

      let params = {
        page: state.currentPage,
        per_page: state.perPage,
        sort_column: state.sortColumn,
        sort_order: state.sortOrder,
        scope: state.searchScope
      }

      params = Object.assign(params, state.searchQuery)

      if (params.accountTag !== null || params.userTag !== null || params.tempoTag !== null ||
        params.vocalTag !== null || params.audioTag !== null || params.genreTag !== null || params.emotionTag !== null) {
        // create new array of all tags if any tags exist
        let tagsArray = [
          ...params.accountTag || [], ...params.userTag || [], ...params.tempoTag || [],
          ...params.vocalTag || [], ...params.audioTag || [], ...params.genreTag || [], ...params.emotionTag || []
        ]

        params.tag_ids = tagsArray.map(function (tag) {
          return tag.id
        })

        params.accountTag = params.userTag = params.tempoTag = params.vocalTag = params.audioTag = params.genreTag = params.emotionTag = null
      }

      if (params.pitch_to_contact !== null) {
        params.pitch_to_contact_ids = [params.pitch_to_contact.id]
        params.pitch_to_contact = null
      }

      if (params.pitch_artists !== null) {
        params.pitch_for_artist_ids = params.pitch_artists.map(function (artist) {
          return artist.id
        })

        params.pitch_artists = null
      }

      if (params.song_titles !== null) {
        params.song_ids = params.song_titles.map(function (song) {
          return song.id
        })

        params.song_titles = null
      }

      for (var propName in params) {
        if (params[propName] === null || params[propName] === undefined) {
          delete params[propName]
        }
      }

      axios({
        url: 'accounts/' + accountId + '/search/playlists',
        params: params,
        paramsSerializer: function (params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets'
          })
        }
      })
        .then(resp => {
          commit(PLAYLIST_SUCCESS)
          commit(PLAYLISTS_SET, resp.data.data)
          commit(PLAYLIST_TOTAL_PAGES_SET, resp.data.meta.last_page)
        })
        .catch(resp => {
          commit(PLAYLIST_ERROR)
        })
    },

    [PLAYLIST_READ]: ({
      commit
    }, id) => {
      commit(PLAYLIST_LOADING)
      commit(PLAYLIST_SET, {})
      return new Promise((resolve, reject) => {
        axios({
          url: 'playlists/' + id
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PLAYLIST_ERROR)
            reject(err)
          })
      })
    },

    [PLAYLIST_CREATE]: ({
      commit,
      getters
    }, data) => {
      let formattedData = {
        title: data.title,
        pitch_to_contact_id: data.pitch_to_contact ? data.pitch_to_contact.id : null,
        shared: data.shared ? data.shared : false,
        team: data.team ? data.team : false,
        comps: data.comps ? data.comps : false,
        artist_ids: data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.id }) : data.pitch_artists ? [data.pitch_artists.id] : []
      }

      if (data.creation_date) {
        formattedData.creation_date = data.creation_date
      }

      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)
        commit(PLAYLIST_SET, {})

        let accountId = getters.getActiveAccountId

        if (!accountId) {
          commit(PLAYLIST_ERROR)
          reject(new Error('no account specified'))
          return false
        }

        axios({
          method: 'post',
          url: 'accounts/' + accountId + '/playlists',
          data: formattedData
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_CREATED, resp.data.data)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PLAYLIST_ERROR)
            reject(err)
          })
      })
    },

    [PLAYLIST_DUPLICATE]: ({
      commit
    }, id) => {
      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)
        commit(PLAYLIST_SET, {})

        axios({
          method: 'post',
          url: 'playlists/' + id + '/duplicate'
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_CREATED, resp.data.data)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PLAYLIST_ERROR)
            reject(err)
          })
      })
    },

    [PLAYLIST_UPDATE]: ({
      commit
    }, {
      id,
      data
    }) => {
      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)
        axios({
          method: 'put',
          url: 'playlists/' + id,
          data: data
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_UPDATED, resp.data.data)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(err => {
            commit(PLAYLIST_ERROR)
            reject(err)
          })
      })
    },

    [PLAYLIST_DELETE]: ({
      commit
    }, id) => {
      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)

        axios({
          method: 'delete',
          url: 'playlists/' + id
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_DELETED, id)
            commit(PLAYLIST_SET, {})
            resolve(resp)
          })
          .catch(resp => {
            commit(PLAYLIST_ERROR)
            reject(resp)
          })
      })
    },

    [PLAYLIST_AUDIO_FILE_DELETE]: ({
      commit
    }, data) => {
      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)

        axios({
          method: 'delete',
          url: 'playlists/' + data.playlistId + '/audio-files/' + data.audioFileId
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_UPDATED, resp.data.data)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(resp => {
            commit(PLAYLIST_ERROR)
            reject(resp)
          })
      })
    },

    [PLAYLIST_AUDIO_FILE_REPOSITION]: ({
      commit
    }, data) => {
      return new Promise(function (resolve, reject) {
        commit(PLAYLIST_LOADING)

        axios({
          method: 'put',
          url: 'playlists/' + data.playlistId + '/audio-files/' + data.audioFileId,
          data: {
            position: data.position
          }
        })
          .then(resp => {
            commit(PLAYLIST_SUCCESS)
            commit(PLAYLIST_UPDATED, resp.data.data)
            commit(PLAYLIST_SET, resp.data.data)
            resolve(resp.data.data)
          })
          .catch(resp => {
            commit(PLAYLIST_ERROR)
            reject(resp)
          })
      })
    },

    [PLAYLIST_CD_LABEL_CREATE]: ({
      commit
    }, data) => {
      commit(PLAYLIST_LOADING)
      var pdfTitle = data.title ? data.title.replace(/[^A-Z0-9]+/ig, '-') + '-cd-label.pdf' : 'cd-label.pdf'
      return new Promise(function (resolve, reject) {
        axios({
          url: 'playlists/' + data.id + '/cd-label',
          params: {
            cd_label: data.cdLabel,
            to: data.pitch_to_contact ? data.pitch_to_contact.name : null,
            for: data.pitch_artists && data.pitch_artists.length ? data.pitch_artists.map(function (artist) { return artist.name }).join(', ') : null,
            from: data.pitch_by_user ? data.pitch_by_user.id : null
          },
          method: 'GET',
          responseType: 'blob'
        })
          .then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', pdfTitle)
            document.body.appendChild(link)
            link.click()
            commit(PLAYLIST_SUCCESS)
            resolve(resp.data)
          })
          .catch(err => {
            commit(PLAYLIST_ERROR)
            reject(err)
          })
      })
    }
  }
}
