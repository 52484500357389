<template>
  <div class="form">
    <div class="field m-b-15">
      <label for="team" class="label">
        <input id="team" type="checkbox" class="checkbox m-r-10" name="team" v-model="playlist.team">
        Team Playlist
      </label>
    </div>
    <div class="field m-b-15">
      <label for="title-input" class="label">Playlist Title</label>
      <input id="title-input" type="text" name="title" class="input" placeholder="Playlist Title"
        v-model="playlist.title" required autocomplete="off" autofocus="true">
    </div>
    <div v-show="!playlist.team">
    <div class="field m-b-15">
      <div class="level m-b-0">
        <div class="level-left">
          <label class="label">Pitching To</label>
        </div>
        <div class="level-right">
          <div>
            <a class="is-primary tooltip is-tooltip-left" data-tooltip="Create Contact" @click="addContact"
              v-if="$can.includes('create-contacts')">
              <IconPlus style="height: 20px; width: 20px;" />
            </a>
          </div>
        </div>
      </div>
      <div class="control">
        <multiselect id="pitch_to_contact_id" v-model="playlist.pitch_to_contact" :options="contacts" track-by="id"
          label="name" :close-on-select="true"></multiselect>
      </div>
    </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Pitching For</label>
      <div class="control">
        <multiselect v-model="playlist.pitch_artists" :multiple="true" :taggable="true" @tag="addArtist"
          tag-placeholder="Artist not found. Press enter to create" :options="artists" track-by="id" label="name"
          :close-on-select="true">
        </multiselect>
      </div>
    </div>
    <div class="field m-b-15">
      <label class="label">Creation Date</label>
      <div class="control tooltip" data-tooltip="If blank, today's date will be used.">
        <div class="control">
          <div class="button is-fullwidth is-primary is-inverted has-text-left"
            @click="openDatePicker('playlist.creation_date', playlist.creation_date, 'Creation Date', 'single')">
            <span v-if="playlist.creation_date">
              {{ playlist.creation_date | momentDate }}
            </span>
            <span v-else>
              Select Date
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!playlist.team">
      <div class="columns">
        <div class="column is-half">
          <div class="field m-b-30 m-t-15">
            <label for="shared" class="label">
              <input id="shared" type="checkbox" class="checkbox m-r-10" name="shared" v-model="playlist.shared">
              Shared
            </label>
          </div>
        </div>
        <div class="column is-half">
          <div class="field m-b-30 m-t-15">
            <label for="comps" class="label">
              <input id="comps" type="checkbox" class="checkbox m-r-10" name="comps" value="1" v-model="playlist.comps">
              Comp
            </label>
          </div>
        </div>
      </div>
    </div>

    <form-modal type="contact" title="Add Contact" :model="contact" :isModal="isModal" :isSaving="isSaving"
      v-on:clear-modal="clearModal" v-on:save-record="saveContact"></form-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import {
  IconPlus
} from '@/Icons/IconBase'
import {
  CREATE,
  CONTACT_CREATE
} from '@/store/actions'
import datepicker from '@/mixins/datepicker'

export default {
  name: 'PlaylistForm',
  components: {
    Multiselect,
    IconPlus
  },
  mixins: [datepicker],
  data() {
    return {
      isModal: false,
      isSaving: false,
      contact: {}
    }
  },
  model: {
    prop: 'playlist',
    event: 'change'
  },
  props: {
    playlist: Object
  },
  computed: {
    artists() {
      return this.$store.state.artist.artists
    },
    contacts() {
      return this.$store.state.contact.contacts
    }
  },
  methods: {
    addArtist(newArtist) {
      let self = this
      this.$store.dispatch(CREATE, {
        resourceName: 'artist',
        data: {
          name: newArtist
        }
      })
        .then(resp => {
          if (self.playlist.pitch_artists) {
            let index = self.playlist.pitch_artists.length
            self.$set(self.playlist.pitch_artists, index, resp)
          } else {
            self.$set(self.playlist, 'pitch_artists', [])
            self.playlist.pitch_artists.push(resp)
          }
        })
    },
    addContact() {
      this.clearModal()
      this.isModal = !this.isModal
    },
    saveContact(data) {
      let self = this
      this.isSaving = true
      if (data.type) {
        data.contact_type_id = data.type.id
      }
      this.$store.dispatch(CONTACT_CREATE, data)
        .then(function (contact) {
          self.isSaving = false
          self.clearModal()
          self.playlist.pitch_to_contact = contact
        }, function (err) {
          self.isSaving = false
          self.clearModal()
          alert(err)
        })
    },
    clearModal() {
      this.contact = {}
      this.isModal = false
    }
  }
}
</script>
