var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-paddingless is-full-width"},[_c('transition',{attrs:{"enter-active-class":"animated fadeIn faster"}},[(_vm.isCreating)?_c('div',[_c('form',{staticClass:"form",on:{"submit":function($event){return _vm.save($event)}}},[_c('div',{staticClass:"field m-b-15"},[_c('textarea-autosize',{staticClass:"textarea",attrs:{"rows":"10","placeholder":"Enter Lyrics here...","disabled":_vm.isLoading},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.save($event)}},model:{value:(_vm.lyricsInput),callback:function ($$v) {_vm.lyricsInput=$$v},expression:"lyricsInput"}})],1),_c('div',{staticClass:"field m-b-15"},[_c('button',{staticClass:"button is-primary is-small",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-danger is-small m-l-5",on:{"click":function($event){$event.preventDefault();return _vm.clearForms.apply(null, arguments)}}},[_vm._v(" Cancel ")])])])]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeIn faster"}},[(!_vm.isCreating)?_c('div',[_c('div',{staticClass:"level is-mobile m-b-5"},[_c('div',{staticClass:"level-left file-editor"},[(
              _vm.song.lyrics &&
              (_vm.$can.includes('edit song lyrics') ||
                _vm.canBeEditedBySongwriterRole)
            )?_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"file-editor download-lyrics tooltip p-r-5",attrs:{"data-tooltip":"Download Lyrics"},on:{"click":function($event){return _vm.downloadLyrics(_vm.song)}}},[_c('a',[_c('IconDownload')],1)])]):_vm._e()]),_c('div',{staticClass:"level-right"},[_c('file-editor',{staticClass:"level-item",attrs:{"title":"Upload Lyrics","file":_vm.song.lyrics,"parentType":"song","parentId":_vm.song.id},on:{"file-updated":_vm.reload}})],1)]),(!_vm.song.lyrics_text)?_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-item has-text-centered"},[_c('p',[_vm._v(" No Lyrics to display. "),(
                _vm.song.lyrics &&
                (_vm.$can.includes('edit song lyrics') ||
                  _vm.canBeEditedBySongwriterRole)
              )?_c('a',{staticStyle:{"border":"none !important"},on:{"click":function($event){$event.preventDefault();return _vm.downloadLyrics(_vm.song)}}},[_vm._v("Download available lyrics here.")]):_vm._e(),(
                _vm.$can.includes('edit song lyrics') ||
                _vm.canBeEditedBySongwriterRole
              )?_c('a',{staticStyle:{"border":"none !important"},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v("Type searchable lyrics here.")]):_vm._e()])])]):_c('div',[_c('div',{staticClass:"media song-lyrics is-hoverable is-paddingless"},[_c('div',{staticClass:"media-content"},[_c('div',{staticClass:"content"},[_c('transition',{attrs:{"enter-active-class":"animated fadeIn faster"}},[(_vm.editingLyrics)?_c('div',[_c('form',{staticClass:"form",on:{"submit":function($event){return _vm.update($event, _vm.song)}}},[_c('div',{staticClass:"field m-b-15"},[_c('textarea-autosize',{staticClass:"textarea",attrs:{"rows":"10","placeholder":"Enter lyrics here...","disabled":_vm.isLoading},model:{value:(_vm.song.lyrics_text),callback:function ($$v) {_vm.$set(_vm.song, "lyrics_text", $$v)},expression:"song.lyrics_text"}})],1),_c('div',{staticClass:"field m-b-15"},[_c('button',{staticClass:"button is-primary is-small",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_c('button',{staticClass:"button is-danger is-small m-l-5",on:{"click":function($event){$event.preventDefault();return _vm.clearForms.apply(null, arguments)}}},[_vm._v(" Cancel ")])])])]):_c('div',[(
                      !_vm.editingLyrics &&
                      _vm.song.lyrics_text &&
                      (_vm.$can.includes('edit song lyrics') ||
                        _vm.canBeEditedBySongwriterRole)
                    )?_c('a',{staticClass:"tooltip",attrs:{"data-tooltip":"Click to edit searchable lyrics"},on:{"click":function($event){return _vm.edit(_vm.song)}}},[_c('p',{staticClass:"song-lyrics-content"},[_vm._v(_vm._s(_vm.song.lyrics_text))])]):_vm._e()])])],1)])])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }