<template>
  <div class="file-editor level-item">
    <div>
      <a
        class="with-label"
        @click.prevent="edit"
        v-if="hasLocalFile && parentType === 'contract'"
      >
        <IconFileText />
        <p>
          {{
            localFile && localFile.label ? localFile.label : localFile.file_name
          }}
        </p>
      </a>
      <a
        class="tooltip"
        :data-tooltip="localFile.label ? localFile.label : 'Edit or View'"
        @click.prevent="edit"
        v-else-if="hasLocalFile && parentType !== 'contract'"
      >
        <IconFileText />
      </a>
      <a class="tooltip" data-tooltip="Upload" @click.prevent="edit" v-else>
        <IconUpload />
      </a>
    </div>

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn faster"
      leave-active-class="animated fadeOut faster"
    >
      <div id="modal" class="modal is-active" v-if="isFileEditorModal">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content card resource-modal-content">
          <div class="card-content">
            <h2 v-if="title" class="title">{{ title }}</h2>
            <h2 v-else class="title">Upload New File</h2>
            <div class="level">
              <div class="level-item has-text-centered">
                <div class="field file-links" v-if="hasLocalFile">
                  <a class="is-primary" @click="open(localFile)">
                    <IconFileText />
                  </a>
                  <a @click="open(localFile)">
                    <p>{{ localFile.file_name }}</p>
                  </a>
                </div>
                <div v-else class="field">
                  <p>No File</p>
                </div>
              </div>
            </div>
            <form class="form" enctype="multipart/form-data">
              <div class="column">
                <div
                  class="field m-b-15"
                  v-if="
                    localFile.fileable_type === 'Wrios\\Models\\Contract' ||
                    this.parentType === 'contract'
                  "
                >
                  <label for="label" class="label">Label</label>
                  <input
                    id="label"
                    type="text"
                    class="input"
                    v-model="label"
                    autocomplete="off"
                    placeholder="Enter a label for the contract"
                  />
                </div>
                <div class="field">
                  <div class="file is-primary is-fullwidth has-name">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        name="file"
                        accept=".doc, .docx, .xml, .pdf, .txt, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref="fileInput"
                        @change="onFileChanged"
                      />
                      <span class="file-cta">
                        <span class="file-icon">
                          <IconUpload />
                        </span>
                        <span class="file-label">Browse</span>
                      </span>
                      <span class="file-name" v-if="selectedFile">{{
                        selectedFile.name
                      }}</span>
                      <span class="file-name" v-else-if="localFile">{{
                        localFile.file_name
                      }}</span>
                      <span class="file-name" v-else
                        >Select file to upload...</span
                      >
                    </label>
                  </div>
                </div>
              </div>

              <div class="level m-t-30 is-mobile">
                <div class="level-left">
                  <div class="level-item">
                    <div>
                      <button
                        class="button is-primary"
                        v-if="localFile.id"
                        v-bind:disabled="!canSave || isSaving"
                        @click.prevent="update"
                      >
                        SAVE
                      </button>
                      <button
                        class="button is-primary"
                        v-else
                        v-bind:disabled="selectedFile == null || isSaving"
                        @click.prevent="create"
                      >
                        UPLOAD
                      </button>
                    </div>
                  </div>
                  <div class="level-item">
                    <a
                      href="#"
                      class="button is-primary is-inverted"
                      @click="close"
                      v-bind:disabled="isSaving"
                      >Cancel</a
                    >
                  </div>
                </div>
                <div
                  class="level-right"
                  v-if="
                    $can.includes('edit song audio') ||
                    canBeEditedBySongwriterRole
                  "
                >
                  <div class="level-item" v-if="hasLocalFile">
                    <delete-button
                      v-on:delete-confirmed="destroy"
                      :disabled="isSaving"
                    ></delete-button>
                  </div>
                </div>
              </div>

              <div class="level m-t-30" v-if="isSaving">
                <div class="level-item">
                  <progress
                    class="progress is-small is-primary"
                    max="100"
                  ></progress>
                </div>
              </div>
            </form>
          </div>
          <button class="modal-close is-large" @click="close"></button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { IconUpload, IconFileText } from '@/Icons/IconBase'

import { FILE_CREATE, FILE_UPDATE, FILE_DELETE } from '@/store/actions'

export default {
  name: 'FileEditor',
  props: ['file', 'parentType', 'parentId', 'title', 'size'],
  components: {
    IconUpload,
    IconFileText
  },

  data() {
    return {
      selectedFile: null,
      localFile: {},
      isFileEditorModal: false,
      isSaving: false,
      label: ''
    }
  },

  created() {
    this.localFile = Object.assign({}, this.$props.file)
    this.label = this.localFile.label
  },

  watch: {
    file: function () {
      this.localFile = Object.assign({}, this.$props.file)
      this.label = this.localFile.label ?? null
    }
  },

  computed: {
    hasLocalFile: function () {
      return this.localFile.id
    },
    canSave: function () {
      return this.label !== this.localFile.label || this.selectedFile != null
    }
  },

  methods: {
    edit() {
      this.isFileEditorModal = !this.isFileEditorModal
    },

    close() {
      this.clearModal()
    },

    clearModal() {
      this.selectedFile = null
      this.isFileEditorModal = false
    },

    onFileChanged(event) {
      this.selectedFile = event.target.files[0]
    },

    open(localFile) {
      if (
        localFile.content_type === 'application/msword' ||
        localFile.content_type ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        window.open(
          'https://docs.google.com/viewerng/viewer?url=' +
            encodeURI(localFile.url),
          '_blank'
        )
        // window.open('https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURI(localFile.url), '_blank')
      } else {
        // need to ensure the filename is encoded properly. there was a bug when a # symbol was not encoded properly in a url
        var encodedUrl =
          localFile.url.substring(0, localFile.url.lastIndexOf('/')) +
          '/' +
          encodeURIComponent(localFile.file_name)
        window.open(encodedUrl, '_blank')
      }
    },

    create() {
      const formData = new FormData()
      formData.append('file', this.selectedFile, this.selectedFile.name)
      formData.append('type', this.parentType)
      formData.append('fileable_id', this.parentId)
      if (this.label) {
        formData.append('file_label', this.label)
      }
      this.isSaving = true
      let self = this
      this.$store.dispatch(FILE_CREATE, formData).then(
        function (resp) {
          self.isSaving = false
          if (self.parentType === 'contract') {
            self.localFile = {}
            self.label = null
          } else {
            self.localFile = resp
          }
          self.clearModal()
          self.report()
        },
        function (err) {
          self.isSaving = false
          self.$store.commit('error', 'File upload failed.')
          console.log(err)
        }
      )
    },

    update() {
      const formData = new FormData()
      if (this.label !== this.localFile.label) {
        formData.append('file_label', this.label)
      }
      if (this.selectedFile) {
        formData.append('file', this.selectedFile, this.selectedFile.name)
      }
      this.isSaving = true
      let self = this
      this.$store
        .dispatch(FILE_UPDATE, {
          id: this.localFile.id,
          formData: formData
        })
        .then(
          function (resp) {
            self.isSaving = false
            self.localFile = resp
            self.clearModal()
            self.report()
          },
          function (err) {
            self.isSaving = false
            self.$store.commit('error', 'File upload failed.')
            console.log(err)
          }
        )
    },

    destroy() {
      let self = this
      this.$store.dispatch(FILE_DELETE, this.localFile.id).then(
        function (resp) {
          self.localFile = {}
          self.report()
          self.clearModal()
        },
        function (err) {
          alert(err)
        }
      )
    },

    report(file) {
      this.$emit('file-updated', self.localFile)
    }
  }
}
</script>
