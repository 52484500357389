<template>
  <div class="content home">
    <section class="page-header is-paddingless is-marginless">
      <div class="level page-actions is-mobile is-centered">
        <div class="level-left">
          <div class="level-item">
            <h1>Play Queue</h1>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item" v-if="queuedAudioFiles.length">
            <queue-clear-button></queue-clear-button>
          </div>
        </div>
      </div>
    </section>
    <section class="section is-paddingless">
      <div class="columns is-marginless">
        <aside class="column is-2 hero"></aside>
        <main class="column is-paddingless">
          <div class="container p-b-90">
            <h5>Now Playing</h5>
            <div v-if="currentAudioFile" class="p-b-30">
              <h2>{{ currentAudioFile.song | orBlank('title') }}</h2>
              <p>
                Written By
                {{ currentAudioFile.song | orBlank('songwriter_names') }}
              </p>
              <p>{{ currentAudioFile | orBlank('file_name') }}</p>
            </div>
            <div v-else class="p-b-30">
              <div v-if="queuedAudioFiles.length">
                <p>
                  You're not playing anything right now. Click the Play button
                  to play the next song in your queue.
                </p>
                <queue-play-button
                  :queuedAudioFileId="queuedAudioFiles[0].id"
                ></queue-play-button>
              </div>
            </div>
            <hr />
            <h5>Up Next</h5>
            <table class="table is-hoverable is-fullwidth">
              <thead class="is-size-7">
                <tr>
                  <th class="has-text-grey-light">Title</th>
                  <th class="has-text-grey-light">Songwriters</th>
                  <th class="has-text-grey-light">Audio File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="is-clickable"
                  v-for="queuedAudioFile in queuedAudioFiles"
                  :key="queuedAudioFile.id"
                >
                  <td class="has-text-dark has-text-weight-bold">
                    {{ queuedAudioFile.audio_file.song | orBlank('title') }}
                  </td>
                  <td class="has-text-dark has-text-weight-bold">
                    {{
                      queuedAudioFile.audio_file.song
                        | orBlank('songwriter_names')
                    }}
                  </td>
                  <td class="has-text-dark has-text-weight-bold">
                    {{ queuedAudioFile.audio_file.file_name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </section>
  </div>
  <!-- /home -->
</template>

<script>
import store from '@/store'

import { QUEUED_AUDIO_FILE_USER_ALL } from '@/store/actions'

export default {
  name: 'AudioPlayerQueue',

  props: {},

  data() {
    return {}
  },

  computed: {
    queuedAudioFiles() {
      return this.$store.getters.getQueuedAudioFiles
    },

    currentAudioFile() {
      const audioFile = this.$store.state.audioPlayer.audioFile
      if (audioFile && audioFile.id) {
        return audioFile
      }
      return null
    }
  },

  beforeRouteEnter: function (to, from, next) {
    store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
    next()
  },

  beforeRouteUpdate: function (to, from, next) {
    store.dispatch(QUEUED_AUDIO_FILE_USER_ALL)
    next()
  },

  beforeCreate: function () {
    document.body.className = 'single'
  },

  methods: {}
}
</script>
