<template>
  <div class="content home">
    <section class="section is-paddingless">
      <div class="columns is-marginless ordered-mobile">
        <aside
          class="column has-background-light hero menu menu-aside fl-2 is-block-mobile"
        >
          <p class="menu-label">People</p>

          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'artists.index' }">
                Artists
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'contacts.index' }">
                Contacts
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'publishing-companies.index' }">
                Publishing Companies
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
            <li v-if="$can.includes('read-record-labels')">
              <router-link :to="{ name: 'record-labels.index' }">
                Record Labels
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'songwriters.index' }">
                Songwriters
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
          </ul>

          <p class="menu-label">Resources</p>
          <ul class="menu-list">
            <li>
              <router-link :to="{ name: 'albums.index' }">
                Albums
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'projects.index' }">
                Projects
                <div class="menu-icon is-pulled-right">
                  <IconChevronRight />
                </div>
              </router-link>
            </li>
          </ul>
        </aside>
        <main
          :class="{ 'is-four-fifths': isWideScreen, 'is-full': !isWideScreen }"
          class="column is-paddingless fl-1"
        >
          <div class="container no-hero">
            <transition
              name="custom-classes-transition"
              enter-active-class="animated fadeIn faster"
            >
              <router-view></router-view>
            </transition>
          </div>
        </main>
      </div>
    </section>
  </div>
</template>

<script>
import { IconChevronRight } from '@/Icons/IconBase'
export default {
  name: 'Industry',
  components: {
    IconChevronRight
  },
  data() {
    return {
      isWideScreen: window.innerWidth > 1087
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkScreenWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth)
  },
  methods: {
    checkScreenWidth() {
      this.isWideScreen = window.innerWidth > 1087
    }
  }
}
</script>
