<template>
  <div class="songwriter-contracts p-t-10">
    <section class="m-b-30" v-if="$can.includes('read-contracts')">
      <div class="level is-marginless">
        <div class="level-left">
          <h3>Contracts</h3>
        </div>
        <div class="level-right">
          <a
            class="is-primary tooltip"
            data-tooltip="Add Contract"
            @click="create"
            v-if="$can.includes('create-contracts')"
          >
            <IconPlus />
          </a>
        </div>
      </div>
      <div class="level" v-if="!contracts.length">
        <p>
          No contracts.
          <a
            class="m-l-5"
            @click="create"
            v-if="$can.includes('create-contracts')"
            >Add a contract here.</a
          >
        </p>
      </div>
      <div
        class="columns contract-panel is-multiline"
        v-else
        v-for="contract in contracts"
        :key="contract.id"
      >
        <div class="column is-3 aside has-background-light">
          <div class="field" v-if="$can.includes('update-contracts')">
            <a class="is-primary" @click="edit(contract)">Edit Contract</a>
          </div>
          <div class="field">
            <label class="label">Name</label>
            <p>{{ contract.name | orBlank }}</p>
          </div>
          <div class="field">
            <label class="label">Start Date</label>
            <p>{{ contract.start_date | momentDate }}</p>
          </div>
          <div class="field">
            <label class="label">End Date</label>
            <p>{{ contract.end_date | momentDate }}</p>
          </div>
          <div v-if="contract.contracts.length > 0" class="grid">
            <div class="is-col-min-12">
              <div class="level mb-0 is-mobile">
                <div class="level-left">
                  <label class="label">Contracts</label>
                </div>
                <div class="level-right">
                  <file-editor
                    :key="contract.id"
                    title="Upload Contract"
                    parentType="contract"
                    :parentId="contract.id"
                    v-on:file-updated="load"
                  ></file-editor>
                </div>
              </div>
            </div>
            <div class="songwriter-contracts-list">
              <div
                class="level-left"
                v-for="file in contract.contracts"
                :key="file.id"
              >
                <div class="level-item">
                  <file-editor
                    :key="file.label"
                    title="Upload Contract"
                    :file="file"
                    parentType="contract"
                    :parentId="contract.id"
                    v-on:file-updated="load"
                  ></file-editor>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="grid">
            <div class="is-col-min-12">
              <div class="level mb-0 is-mobile">
                <div class="level-left">
                  <label class="label">Contracts</label>
                </div>
                <div class="level-right">
                  <file-editor
                    title="Upload Contract"
                    :file="contract.contract"
                    parentType="contract"
                    :parentId="contract.id"
                  ></file-editor>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-9">
          <div class="p-b-30 top-section">
            <ContractOptionPeriods :contractId="contract.id" />
          </div>
          <div class="p-t-30 p-b-30">
            <ContractWriterShares :contractId="contract.id" />
          </div>
          <div class="p-t-30 p-b-30">
            <ContractPublisherShares :contractId="contract.id" />
          </div>
        </div>
        <div class="column is-12" style="border-top: 1px solid #e8e8e8">
          <note-control
            :notableId="contract.id"
            notableType="contract"
          ></note-control>
        </div>
      </div>
    </section>

    <form-modal
      type="contract"
      :title="formTitle"
      :model="selectedContract"
      :message="modalMessage"
      :isModal="isModal"
      :canDelete="formCanDelete"
      v-on:clear-modal="clearModal"
      v-on:save-record="save"
      v-on:delete-record="destroy"
    ></form-modal>
  </div>
</template>

<script>
import uiHelper from '@/mixins/uiHelper'
import { IconPlus } from '@/Icons/IconBase'

import ContractPublisherShares from '@/components/ContractPublisherShares'
import ContractWriterShares from '@/components/ContractWriterShares'
import ContractOptionPeriods from '@/components/ContractOptionPeriods'

import { CONTRACTS_SET } from '@/store/mutations'
import {
  CONTRACT_ALL_FOR_SONGWRITER,
  CONTRACT_UPDATE,
  CONTRACT_CREATE,
  CONTRACT_DELETE
} from '@/store/actions'

export default {
  name: 'SongwriterContracts',
  mixins: [uiHelper],
  components: {
    IconPlus,
    ContractPublisherShares,
    ContractWriterShares,
    ContractOptionPeriods
  },

  props: ['songwriterId'],

  data() {
    return {
      formTitle: '',
      formCanDelete: false,
      isModal: false,
      isEditModal: false,
      selectedContract: {},
      isSaving: false,
      modalMessage: ''
    }
  },

  destroyed: function () {
    this.$store.commit(CONTRACTS_SET, {})
  },

  computed: {
    resourceState() {
      return this.$store.state.contract
    },

    contracts() {
      return this.$store.state.contract.contracts
    }
  },

  methods: {
    load() {
      if (this.songwriterId) {
        this.$store.dispatch(CONTRACT_ALL_FOR_SONGWRITER, {
          songwriterId: this.songwriterId,
          data: null
        })
      }
    },

    create() {
      this.clearModal()
      this.formTitle = 'Add Contract'
      this.modalMessage = 'You can add more details later.'
      this.isModal = !this.isModal
    },

    edit(contract) {
      this.formCanDelete = true
      this.formTitle = 'Update Contract'
      this.selectedContract = contract
      this.isModal = !this.isModal
    },

    save(input) {
      if (this.selectedContract.id) {
        this.$store
          .dispatch(CONTRACT_UPDATE, {
            id: this.selectedContract.id,
            data: input,
            songwriterId: this.songwriterId
          })
          .then(() => {
            this.clearModal()
          })
          .catch((err) => {
            let errors = err.response.data.errors
            let messages = []

            if (errors.name) {
              messages.push(errors.name)
            }

            if (errors.start_date) {
              messages.push(errors.start_date)
            }

            if (errors.end_date) {
              messages.push(errors.end_date)
            }
            this.$store.commit('error', messages.join('<br>'))
          })
      } else {
        this.$store
          .dispatch(CONTRACT_CREATE, {
            songwriterId: this.songwriterId,
            data: input
          })
          .then(() => {
            this.clearModal()
          })
          .catch((err) => {
            let errors = err.response.data.errors
            let messages = []

            if (errors.name) {
              messages.push(errors.name)
            }

            if (errors.start_date) {
              messages.push(errors.start_date)
            }

            if (errors.end_date) {
              messages.push(errors.end_date)
            }
            this.$store.commit('error', messages.join('<br>'))
          })
      }
    },

    destroy(contract) {
      this.$store.dispatch(CONTRACT_DELETE, {
        id: contract.id,
        songwriterId: this.songwriterId
      })
      this.clearModal()
    },

    clearModal() {
      this.formCanDelete = false
      this.formTitle = ''
      this.isModal = false
      this.selectedContract = {}
      this.modalMessage = ''
    }
  }
}
</script>
